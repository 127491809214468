import React from 'react';
import i18next from 'i18next';
import { Tag, Typography } from 'antd';
import { ColumnsType, ExpandableConfig } from 'antd/lib/table/interface';

import useCustomTranslation from '@/hooks/useCustomTranslation';

import { formatMoney } from '@/shared/helpers';
import precision from '@/shared/helpers/precision';
import VisibilityTag from '@/shared/VisibilityTag';
import DotsDropdown from '@/shared/components/DotsDropdown';
import SearchableTable from '@/shared/components/table/components/SearchableTable';

import DropdownOption from '@/interfaces/DropdownOption';
import IInventoryProduct from '@/interfaces/IInventoryProduct';
import formatQty from '@/shared/helpers/formatQty';

import QuantityUnit from '../QuantityUnit';

interface Props {
  inventoryProducts: IInventoryProduct[];
  loading: boolean,
  showAvailableAndPending?: boolean;
  showPrice?: boolean;
  showVisibility?: boolean;
  subhead?: string | undefined,
  children?: any,
  pagination?: any,
  expandable?: ExpandableConfig<IInventoryProduct>;
  actions?: ((prod: IInventoryProduct) => DropdownOption[]);
}

const InventoryProductsList = ({
  inventoryProducts,
  loading,
  showAvailableAndPending = true,
  showPrice = true,
  showVisibility = true,
  subhead,
  children,
  pagination,
  expandable,
  actions,
}: Props) => {
  const { t } = useCustomTranslation();
  const lang = i18next.language;

  const columns: ColumnsType<IInventoryProduct> = [
    {
      title: t('g.sku'),
      dataIndex: ['sku'],
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: t('g.product_name'),
      dataIndex: [lang === 'en' ? 'nameEn' : 'nameEs'],
    },
    ...showPrice ? [{
      title: t('g.price'),
      dataIndex: ['unitPrice'],
      render: (value: any) => (<p style={{ textAlign: 'right' }}>{formatMoney(value)}</p>),
    }] : [],
    ...showVisibility ? [{
      title: t('g.visibility'),
      dataIndex: ['visibility'],
      render: (text: string) => (
        <VisibilityTag visibility={text} />
      ),
    }] : [],
    ...showAvailableAndPending ? [{
      title: t('g.available_for_sale'),
      render: (_: any, value: IInventoryProduct) => {
        const availableForSale = formatQty({
          qty: value.forSale - value.pending,
          decimalPoints: value.decimalPoints,
        });
        
        return (
          <Typography.Text style={{ float: 'right' }}>
            {`${availableForSale} ${value.unit}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t('g.not_available_for_sale'),
      dataIndex: ['forSale'],
      render: (_: any, value: IInventoryProduct) => {
        const notAvailableForSale = formatQty({
          qty: value.total - value.forSale,
          decimalPoints: value.decimalPoints,
        });

        return (
          <Typography.Text style={{ float: 'right' }}>
            {`${notAvailableForSale} ${value.unit}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t('g.sold'),
      dataIndex: ['pending'],
      render: (_: IInventoryProduct['pending'], value: IInventoryProduct) => {
        const pendingTotal = formatQty({
          qty: value.pending,
          decimalPoints: value.decimalPoints,
        });

        return (
          <Typography.Text style={{ float: 'right' }}>
            {`${pendingTotal} ${value.unit}`}
          </Typography.Text>
        );
      },
    }] : [],
    {
      title: t('g.total'),
      dataIndex: ['total'],
      render: (_, value: IInventoryProduct) => {
        const total = formatQty({
          qty: value.total,
          decimalPoints: value.decimalPoints,
        });

        if (!total) {
          return (
            <Typography.Text style={{ float: 'right' }}>
              --
            </Typography.Text>
          );
        }

        return (
          <Typography.Text style={{ float: 'right' }}>
            {`${total} ${value.unit}`}
          </Typography.Text>
        );
      },
    },
    ...actions ? [
      {
        title: t('g.actions'),
        dataIndex: 'id',
        width: 30,
        render: (text: string, value: any) => {
          const dropdownMenu = actions(value);
          if (dropdownMenu.length === 0) return null;

          return (
            <div className="panel__btns" style={{ top: 20 }}>
              <DotsDropdown dropdownMenu={dropdownMenu} />
            </div>
          );
        },
      },
    ] : [],
  ];
  return (
    <SearchableTable
      subhead={subhead}
      columns={columns}
      data={inventoryProducts}
      loading={loading}
      pagination={pagination}
      disableSearch
      rowKey="productId"
      expandable={expandable}
    >
      {children}
    </SearchableTable>
  );
};


export default InventoryProductsList;

