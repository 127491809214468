import React, { useState, useEffect } from 'react';


export interface UseService<T> {
  data: T[],
  loading: boolean,
  reload: () => Promise<void>,
  setCurrentParams: React.Dispatch<any>;
}

interface UseServiceProps<T> {
  fetchData: (params: any) => Promise<T[]>;
  forceParams?: boolean,
  params?: any;
}

function useService<T>({ 
  fetchData, 
  forceParams = false,
  params,
}: UseServiceProps<T>): UseService<T> {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentParams, setCurrentParams] = useState(params || {});

  async function reload() {
    setLoading(true);
    return fetchData(currentParams)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
        setData([]);
      })
      .finally(() => {
          setLoading(false);
      });
  }

  useEffect(() => {
    const isSomeParamsNull = Object.values(currentParams).some(param => param === null);

    if (forceParams && isSomeParamsNull) return;

    reload();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);
  
  return {
    data,
    loading,
    reload,
    setCurrentParams,
  };
}

export default useService;
